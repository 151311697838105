import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

const TermsAndConditionsPage = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>TERMS AND CONDITIONS</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>Effective Date: 06 June 2023</p>

            <p>
              Thank you for visiting Travel-Lingual.com. These Terms and
              Conditions govern your use of our website. By accessing or using
              our website, you agree to comply with these Terms and Conditions.
              Please read them carefully.
            </p>

            <p>
              1. Service Description Travel-Lingual.com serves as an affiliate
              site for tours and attractions. We promote and advertise the
              inventory of third-party providers. We do not handle any booking
              on our site. We refer users to the third-party sites where
              bookings can be made. We receive a commission for any bookings
              made through our referral links.
            </p>

            <p>
              2. User Responsibilities When using Travel-Lingual.com, users are
              responsible for the following: <br></br>- Subscribing to our
              social channels and newsletters, if desired. <br></br>- Visiting
              and exploring any page on our website. <br></br>- Visiting
              third-party sites through our referral links.
            </p>

            <p>
              3. Intellectual Property Travel-Lingual.com advertises third-party
              inventory and does not claim ownership of any intellectual
              property related to the tours and attractions promoted. We do not
              modify pricing or inventory details. All intellectual property
              rights belong to the respective third-party providers.
            </p>

            <p>
              4. Limitations of Liability Travel-Lingual.com acts solely as an
              intermediary between users and third-party providers. We do not
              hold responsibility for the tours, attractions, or any other
              products or services sold by these providers. Users understand and
              agree that any bookings or transactions made on third-party sites
              are subject to the terms and conditions of those sites. The
              third-party providers are solely responsible for booking,
              confirming, handling, and delivering their respective tours or
              products. Travel-Lingual.com does not guarantee the availability,
              accuracy, or quality of the tours or attractions listed.
            </p>

            <p>
              5. Payment and Refunds Travel-Lingual.com does not process any
              payments on its platform. All payments for bookings are made
              directly on the third-party sites. Any payment or refund-related
              issues should be addressed directly with the respective
              third-party providers.
            </p>

            <p>
              6. Governing Law These Terms and Conditions shall be governed by
              and construed in accordance with the laws of the United Kingdom.
              Any disputes arising from these Terms and Conditions or your use
              of Travel-Lingual.com shall be subject to the exclusive
              jurisdiction of the courts of the United Kingdom.
              Travel-Lingual.com is owned and operated by Content Epic Ltd, a
              company registered in the United Kingdom. Content Epic Ltd is the
              legal entity responsible for the operation and management of the
              website.
            </p>

            <p>
              7. Modifications We may update or modify these Terms and
              Conditions at any time without prior notice. It is your
              responsibility to review the most current version of the Terms and
              Conditions posted on our website. By continuing to use
              Travel-Lingual.com] after any modifications, you accept and agree
              to abide by the revised Terms and Conditions.
            </p>

            <p>
              If you have any questions, concerns, or requests regarding these
              Terms and Conditions, please contact us at
              hello@travel-lingual.com.
            </p>

            <p>
              By using our website, you acknowledge that you have read,
              understood, and agree to these Terms and Conditions.
            </p>

            <p>Last Updated: 06 June 2023</p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default TermsAndConditionsPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Terms & Conditions | Travel-Lingual"}
      description={
        "Understand the rules and guidelines that govern your experience on Travel-Lingual.com."
      }
      pathname={location.pathname}
    />
  );
};
